<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>客服诊金订单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <!-- <p style="float: left;margin: 0;">选择状态：</p> -->
      <el-select
        v-model="select1"
        placeholder="请选择订单状态"
        style="width: 150px; margin-right: 10px"
        clearable
        @clear="select1 = ''"
        size="small"
      >
        <el-option
          v-for="item in options1"
          :key="item.id"
          :label="item.value"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <!-- <p style="float: left;margin: 0;">选择日期：</p> -->
      <el-date-picker
        v-model="date"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        clearable
        style="width: 280px; margin-right: 10px"
        size="small"
      >
      </el-date-picker>
      <el-button
        type="primary"
        style="margin-right: 10px"
        @click="getdata1(1)"
        size="small"
        >查询</el-button
      >
      <el-button type="primary" @click="exportorder" size="small"
        >导出Excel</el-button
      >
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="id" label="编号" width="80"></el-table-column>
      <el-table-column
        prop="pt_no"
        label="患者姓名"
        width="120"
      ></el-table-column>
      <el-table-column
        label="订单编号"
        prop="yf_order_no"
        width="200"
      ></el-table-column>
      <el-table-column label="金额" prop="trans_amount">
        <template slot-scope="scope">
          <span>{{
            scope.row.trans_amount | number_format(scope.row.trans_amount)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        prop="create_time"
        width="200"
      ></el-table-column>
      <el-table-column
        label="支付时间"
        prop="pay_time"
        width="200"
      ></el-table-column>
      <el-table-column label="支付状态" prop="state" width="240">
        <template slot-scope="scope">
          <div class="opt doc">
            <span v-if="scope.row.state == -1">取消付款</span>
            <span v-if="scope.row.state == 0">新订单</span>
            <span v-if="scope.row.state == 1">已付款</span>
            <span v-if="scope.row.state == 2">已确认</span>
            <span v-if="scope.row.state == 6">已退款</span>
            <el-button
              style="margin-left: 10px"
              size="mini"
              type="primary"
              @click="orderdetail(scope.row, scope.$index)"
              >详情(欧西思)</el-button
            >
            <p style="margin-top: 10px">
              <el-button
                size="mini"
                type="primary"
                @click="orderdetailzhrs(scope.row, scope.$index)"
                >详情(中辉荣盛)</el-button
              >
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        :hide-on-single-page="value"
      >
      </el-pagination>
    </p>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      id="tableBox"
      v-show="false"
    >
      <el-table-column prop="id" label="编号"></el-table-column>
      <el-table-column prop="pt_no" label="患者姓名"></el-table-column>
      <el-table-column label="订单编号" prop="yf_order_no"></el-table-column>
      <el-table-column label="金额" prop="trans_amount">
        <template slot-scope="scope">
          <span>{{
            scope.row.trans_amount | number_format(scope.row.trans_amount)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="create_time"></el-table-column>
      <el-table-column label="支付时间" prop="pay_time"></el-table-column>
      <el-table-column label="支付状态" prop="state" width="240">
        <template slot-scope="scope">
          <div class="opt doc">
            <span v-if="scope.row.state == -1">取消付款</span>
            <span v-if="scope.row.state == 0">新订单</span>
            <span v-if="scope.row.state == 1">已付款</span>
            <span v-if="scope.row.state == 2">已确认</span>
            <span v-if="scope.row.state == 6">已退款</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="查看详情" :visible.sync="looktkdetail">
      <table class="tkdetail">
        <tr>
          <td class="title">订单号</td>
          <td>{{ tkdetaildes.out_trade_no }}</td>
        </tr>
        <!-- <tr>
          <td>退款次数</td>
          <td>{{tkdetaildes.refund_count}}</td>
        </tr> -->
        <tr>
          <td class="title">金额</td>
          <td>{{ filtermoney(tkdetaildes.cash_fee) }}</td>
        </tr>
        <tr>
          <td class="title">付款方式</td>
          <td>{{ tkdetaildes.bank_type }}</td>
        </tr>
        <tr>
          <td class="title">time_end</td>
          <td>{{ tkdetaildes.time_end }}</td>
        </tr>
        <!-- <tr>
          <td class="title">结果</td>
          <td>{{filtercode(tkdetaildes.result_code,tkdetaildes.return_code)}}</td>
        </tr> -->
        <!-- <tr>
          <td class="title">return_code</td>
          <td>{{tkdetaildes.return_code}}</td>
        </tr> -->
        <tr>
          <td class="title">总金额</td>
          <td>{{ filtermoney(tkdetaildes.total_fee) }}</td>
        </tr>
        <tr>
          <td class="title">订单状态</td>
          <td>{{ tkdetaildes.trade_state }}</td>
        </tr>
        <tr>
          <td class="title">订单状态描述</td>
          <td>{{ tkdetaildes.trade_state_desc }}</td>
        </tr>
        <tr>
          <td class="title">支付类型</td>
          <td>{{ tkdetaildes.trade_type }}</td>
        </tr>
        <tr>
          <td class="title">交易编号</td>
          <td>{{ tkdetaildes.transaction_id }}</td>
        </tr>
      </table>
    </el-dialog>
  </div>
</template>
<script>
const FileSaver = require("file-saver");
const XLSX = require("xlsx");
export default {
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      tableData: [],
      date: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      select1: "",
      options1: [
        { id: 0, value: "新订单" },
        { id: 1, value: "已付款" },
        { id: 2, value: "已确认" },
        { id: 6, value: "已退款" },
      ],
      looktkdetail: false,
      tkdetaildes: {},
    };
  },
  mounted() {
    // this.getdata1(1);
  },
  filters: {
    number_format: function (number) {
      var decimals = 2;
      var dec_point = ".";
      var thousands_sep = ",";
      number = (number + "").replace(/[^0-9+-Ee.]/g, "");
      var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 2 : Math.abs(decimals),
        sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
        dec = typeof dec_point === "undefined" ? "." : dec_point,
        s = "",
        toFixedFix = function (n, prec) {
          var k = Math.pow(10, prec);
          return "" + Math.ceil(n * k) / k;
        };
      s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
      var re = /(-?\d+)(\d{3})/;
      while (re.test(s[0])) {
        s[0] = s[0].replace(re, "$1" + sep + "$2");
      }
      if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
      }
      return s.join(dec);
    },
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata1(currentPage);
    },
    getdata1(page) {
      if (this.date.length <= 0) {
        this.$message({
          message: "请选择时间",
          type: "error",
        });
        return;
      }
      this.list = [];
      this.axios
        .get(
          "/yf/get_gu_pat_servorder?page=" +
            page +
            "&size=" +
            this.PageSize +
            "&input_id=" +
            "&datetime1=" +
            this.date[0] +
            "&datetime2=" +
            this.date[1] +
            "&state=" +
            this.select1
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          } else {
            this.list = [];
            this.totalCount = 0;
          }
        });
    },
    // 执行下载
    async exportorder() {
      await this.setData();
      this.exportOut();
    },
    setData: async function () {
      this.arr = [];
      this.tableData = [];
      let num = Math.ceil(this.totalCount / this.PageSize);
      let m = "";
      let a = [];
      for (m = 1; m <= num; m++) {
        a = await this.funA(m);
        this.arr = this.arr.concat(a.data.result);
      }
      this.tableData = this.arr;
    },
    funA: async function (m) {
      return await this.axios.get(
        "/yf/get_gu_pat_servorder?page=" +
          m +
          "&size=" +
          this.PageSize +
          "&datetime1=" +
          this.date[0] +
          "&datetime2=" +
          this.date[1] +
          "&state=" +
          this.select1
      );
    },
    //下载表格
    exportOut() {
      var xlsxParam = { raw: true }; //转换成excel时，使用原始的格式
      var wb = XLSX.utils.table_to_book(
        document.querySelector("#tableBox"),
        xlsxParam
      );
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          new Date().getTime() + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
    //查看订单详情
    orderdetail(row, index) {
      this.getorderdetail(row);
    },
    filtermoney(val) {
      if (val) {
        return "￥" + val / 100;
      } else {
        return "";
      }
    },
    filtercode(val1, val2) {
      if (val1 == "SUCCESS" && val2 == "SUCCESS") {
        return "成功";
      } else {
        return "失败";
      }
    },
    //查看详情中辉荣盛
    orderdetailzhrs(row) {
      this.axios
        .post(
          "http://192.168.11.253:8080/wpay_zhonghui/example/orderquery.php?",
          // "http://112.250.105.14:10087/wpay_zhonghui/example/orderquery.php?",
          this.qs.stringify({ out_trade_no: row.yf_order_no })
        )
        .then((res) => {
          if (
            res.data.result_code == "SUCCESS" &&
            res.data.return_code == "SUCCESS"
          ) {
            this.looktkdetail = true;
            console.log(res.data);
            this.tkdetaildes = res.data;
          } else {
            this.$message({
              message: res.data.err_code_des,
              type: "error",
            });
          }
        });
    },
    getorderdetail(row) {
      this.axios
        .post(
          "http://192.168.11.253:8080/wpay/example/orderquery.php?",
          // "http://112.250.105.14:10087/wpay/example/orderquery.php?",
          this.qs.stringify({ out_trade_no: row.yf_order_no })
        )
        .then((res) => {
          if (
            res.data.result_code == "SUCCESS" &&
            res.data.return_code == "SUCCESS"
          ) {
            this.looktkdetail = true;
            console.log(res.data);
            this.tkdetaildes = res.data;
          } else {
            this.$message({
              message: res.data.err_code_des,
              type: "error",
            });
          }
        });
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
.add {
  width: 100%;
  height: 50px;
  text-align: left;
  float: left;
}
.tkdetail tr, .tkdetail tr td{
  height: 30px;
  text-align: left;
}
.tkdetail .title {
  width: 130px;
}
</style>
